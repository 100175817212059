<template>
	<input type="file" @change="postOtp" ref="file"/>
</template>

<script>
	import axios from 'axios'
	export default {
		name: 'test_otp_confirm'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'OTP Authentication Test'
					, not_header: true
					, not_footer: true
				}
				, gogle_otp_certfc_number: ''
			}
		}

		, methods: {
			postOtp: async function(){
				let file = this.$refs.file.files[0]
				console.log('file', file)
				let data = new FormData();

				data.append('member_number', 'M0983440441246222763')
				data.append('file_upload_code', 'CM00700015')
				data.append('appendix_file_div_code', 'CA01000001')
				data.append('appendix_file_name',  file)


				const options = {
					method: 'POST',
					body: data,
					// If you add this, upload won't work
					// headers: {
					//   'Content-Type': 'multipart/form-data',
					// }
					headers: {
						Accept: 'application/json',
						'A': this.$encodeStorage.getSessionToken(),
						'V': '1.0.0'
					}
				};

				let url = 'https://devappapi.mafinft.com/mafiaa/com/appendixfilereg'
				fetch(url, options).then( (result) => {
					console.log(result)
				}).catch( (error) => {
					console.log(error)
				});

				let config = {
					headers: {
						'A': this.$encodeStorage.getSessionToken(),
						'V': '1.0.0'
					}
					, transformRequest: data => data
				}
				await axios.post(url, data, config)
				axios({
					url    : 'https://devappapi.mafinft.com/mafiaa/com/appendixfilereg',
					method : 'POST',
					data   : data,
					headers: {
						Accept: 'application/json',
						'A': this.$encodeStorage.getSessionToken(),
						'V': '1.0.0'
					}
				})
					.then(function (response) {
						console.log("response :", response);
					})
					.catch(function (error) {
						console.log("error from image :", error);
					})
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>